.app-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.content-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.left-menu-section {
  width: 67px;
  flex-shrink: 0;
  border-right: 1px solid #e5e7eb;
  overflow: hidden;
}

.main-section {
  width: 100%;
  height: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.flex-column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.right-container {
  width: 100%;
  /* height: 100%; */
  overflow: auto;
  height: calc(100vh - 52px);
}

ul.left-menu {
  padding: 10px;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  padding-right: 17px;
  box-sizing: content-box;
}

ul.left-menu li {
  width: 47px;
  height: 47px;
  border-radius: 5px;
  font-size: 20px;
  margin-bottom: 16px;
}

ul.left-menu li img,
ul.left-menu li i {
  width: 28px;
  height: 28px;
  font-size: 28px;
  line-height: 28px;
  margin: 0 !important;
}

ul.left-menu li > a {
  display: block;
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.left-menu li:hover,
ul.left-menu li.active {
  background-color: #e5e7eb;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.no-drag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

.root,
#root,
#docs-root {
  --primary-color: #f16d94;
  --second-primary-color: #1a83c0;
  --secondary-color: #979797;
  --secondary-text-color: #595959;
  --danger-color: #dc3545;
  --border-color: #7b7b7b;
  --disabled-border-color: #c7c7c7;
  --border-active-img-color: #bcbcbc;
  --active-color: #1a83c0;
  --info-color: #28ace2;
  --inactive-color: #cdcdcd;
  --support-text-color: gray;

  --bg-primary-color: #fdeeef;
  --dark-bg-primary-color: #fabacf;
  --lighter-bg-primary-color: #ffd9e5;

  --z-index-retake-result: 1000;
  --z-index-backdrop: 1300;
  --z-index-button-on-backdrop: 1300;
  --z-index-freeze-webcam-loading: 1200;
  --z-index-loading: 1200;
  --z-index-popup: 1400;
  --z-index-popover: 1400;
  --z-index-keyboard: 1300;
}

*::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  background-color: #f5f5f5 !important;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary-color) !important;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  ) !important;
}

*::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  background-color: #f5f5f5 !important;
}

.line-clamp {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  white-space: normal;
}
.line-clamp-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  white-space: normal;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.line-clamp-2 {
  -webkit-line-clamp: 2;
}
.line-clamp-3 {
  -webkit-line-clamp: 3;
}
.line-clamp-4 {
  -webkit-line-clamp: 4;
}
.line-clamp-5 {
  -webkit-line-clamp: 5;
}
.line-clamp-6 {
  -webkit-line-clamp: 6;
}

.hf-error-text {
  color: red;
  text-align: left;
}
p.hf-error-text {
  margin-top: -12px !important;
}

.hf-form-field-date-picker > .field > .field {
  margin: 0 !important;
}

.hf-form-field-date-picker > .field {
  width: 100%;
}
